import { fnv1a, isFunction } from "@services/utils";
import { isEmpty, memoize } from "lodash";
import { IntlErrorCode } from "@formatjs/intl";
import { OnErrorFn, OnWarnFn } from "@formatjs/intl/src/types";

import { di } from "@di";
import { readFromLocalStorage, writeToLocalStorage } from "@services/data";
import { reportError } from "@services/logger";
import {
  AVAILABLE_LANG_CODES,
  COMPILED_LOCALES_PATH,
  DATE_LOCALE_LOADERS,
  DEFAULT_LANG_CODE,
  SAVED_LANG_CODE_LOCAL_STORAGE_KEY,
} from "./constants";
import { LangCode, LocaleData, WithLocales } from "./types";

export const isLangCodeSupported = (
  langCode: undefined | null | string | LangCode // eslint-disable-line @typescript-eslint/no-redundant-type-constituents
): langCode is LangCode => {
  let availableLocales = new Set(AVAILABLE_LANG_CODES);

  if (di.hasRegistration("locales")) {
    availableLocales = new Set(di.resolve("locales"));
  } else {
    reportError(
      `[isLangCodeSupported] Dependency "locales" is not registered.`
    );
  }

  return availableLocales.has(<LangCode>langCode);
};

export const getUserLanguageCode = (): LangCode => {
  const { value: savedLangCode, error } = readFromLocalStorage<string | null>(
    SAVED_LANG_CODE_LOCAL_STORAGE_KEY,
    null
  );

  if (error) {
    reportError(
      "[getUserLanguageCode] Unable to get saved user language code!\n",
      error
    );
  } else if (isLangCodeSupported(savedLangCode)) {
    return savedLangCode;
  }

  reportError(
    `[getUserLanguageCode] Saved language code "${savedLangCode}" is not supported yet or invalid. Falling back to browser language...`
  );

  const browserLang = navigator.language || navigator.languages.at(0);
  const browserLanguageCode = browserLang?.split?.("-").at(0);

  if (isLangCodeSupported(browserLanguageCode)) {
    return browserLanguageCode;
  }

  reportError(
    `[getUserLanguageCode] Browser language code "${browserLanguageCode}" is not supported yet or invalid. Falling back to default language...`
  );

  return DEFAULT_LANG_CODE;
};

export const saveUserLanguageCode = (langCode: LangCode) => {
  const { error } = writeToLocalStorage<LangCode>(
    SAVED_LANG_CODE_LOCAL_STORAGE_KEY,
    langCode
  );

  if (error) {
    reportError(
      "[saveUserLanguageCode] Unable to save user language code!\n",
      error
    );

    throw error;
  }
};

export const loadLocaleData = async (
  langCode: LangCode
): Promise<LocaleData> => {
  const { default: messages } = await import(
    `${COMPILED_LOCALES_PATH}${langCode}.json`
  );

  return messages;
};

export const handleIntlError: OnErrorFn = (error) => {
  reportError(
    `[handleIntlError] ${error.message}`,
    error.code === IntlErrorCode.MISSING_TRANSLATION ? undefined : error
  );
};

export const handleIntlWarning: OnWarnFn = (message) => {
  reportError(`[handleIntlWarning] ${message}`);
};

/**
 * @internal Do not use anywhere except `extractLocalized`
 */
function getLangCode<T extends LangCode, S extends LangCode>(
  localeOverride: T | undefined,
  fallbackLocale: S
): T | S;

/**
 * @internal Do not use anywhere except `extractLocalized`
 */
function getLangCode<T extends LangCode, S extends LangCode>(
  localeOverride: T | undefined,
  fallbackLocale?: S
): T | S | undefined;

/**
 * @internal Do not use anywhere except `extractLocalized`
 */
function getLangCode<T extends LangCode, S extends LangCode>(
  localeOverride: T | undefined,
  fallbackLocale?: S
): T | S | undefined {
  if (isLangCodeSupported(localeOverride)) {
    return localeOverride;
  }

  if (localeOverride) {
    reportError(
      `[extractLocalized#getLangCode] Locale ${String(
        localeOverride
      )} is not supported currently and will be ignored.`
    );
  }

  if (!di.hasRegistration("langCode")) {
    reportError(
      `[extractLocalized#getLangCode] Dependency "langCode" is not registered. Fallback locale will be returned.`
    );

    return fallbackLocale;
  }

  return di.resolve("langCode") as T | S;
}

/**
 * Extracts localized data from the passed object;
 *
 * Attention!
 * This is a temporary solution to handle localized strings from the `Config`;
 * We should rewrite configs to the pre-compiled (with `AST`) messages,
 * build with the code-splitting and cache management,
 * and handle them via `react-intl` as regular messages.
 *
 * Note: It uses `DEFAULT_LANG_CODE` and `di.resolve("langCode")` as an external dependencies.
 */
export const extractLocalized = memoize(
  <T, F = T>(
    localizedData: WithLocales<T> | undefined | null,
    fallback?: F,
    localeOverride?: LangCode
  ): T => {
    const langCode = getLangCode(localeOverride, DEFAULT_LANG_CODE);

    try {
      if (!langCode) {
        throw new Error("Invalid or missing locale!");
      }

      if (!localizedData || typeof localizedData !== "object") {
        throw new Error(
          `Invalid or missing localizedData! Expected type of Object, got ${JSON.stringify(
            localizedData
          )} instead. Fallback provided: ${JSON.stringify(fallback)}.`
        );
      }

      if (!(langCode in localizedData)) {
        throw new Error(
          `Locale "${langCode}" doesn't exist in the localizedData ${JSON.stringify(
            localizedData,
            null,
            2
          )}.`
        );
      }
      const extracted = localizedData[langCode];

      if (isEmpty(extracted)) {
        throw new Error(
          `Extracted value is empty, got ${JSON.stringify(
            extracted
          )}. Arguments:\n${JSON.stringify(
            {
              localeOverride: String(localeOverride),
              derivedLocale: langCode,
              fallback,
              localizedData,
            },
            null,
            2
          )}.`
        );
      }

      return extracted as T;
    } catch (error) {
      if (error instanceof Error) {
        reportError("[extractLocalized]", error);
      } else {
        reportError("[extractLocalized] An unexpected error occurred!");
      }

      return (localizedData?.[DEFAULT_LANG_CODE] || fallback) as T;
    }
  },
  (localizedData, fallback, localeOverride) => {
    const langCode = getLangCode(localeOverride, DEFAULT_LANG_CODE);
    const fallbackKey = fallback ? fnv1a(JSON.stringify(fallback)) : "-";
    const localizedDataKey = localizedData
      ? fnv1a(JSON.stringify(localizedData))
      : "-";

    return [localizedDataKey, fallbackKey, String(langCode)].join("|");
  }
) as <T, F = T>(
  localizedData: WithLocales<T> | undefined | null,
  fallback?: F,
  localeOverride?: LangCode
) => T;

export const loadDateLocale = async (
  langCode: LangCode,
  localeLoaders = DATE_LOCALE_LOADERS
) => {
  const localeLoader = localeLoaders[langCode];

  if (!isFunction(localeLoader)) {
    throw new Error(
      `[DateTimeInput] LangCode ${langCode} doesn't exist in the localeLoaders or invalid.`
    );
  }

  const { default: dateLocaleData } = await localeLoader();

  return dateLocaleData;
};
