import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { OIDCApp, Spinner } from "@frontend/assaia-ui";
import "./style.scss";
import { ErrorMessage } from "@ui/Common/ErrorMessage";
import { reportError, reportMessage } from "@services/logger";
import { OpenIdConfig } from "@models/common";
import { readFromLocalStorage } from "@services/data";
import { browserHistory } from "@services/react";
import { InitialApi } from "@api/InitialApi";

const LazyGlobalProvider = lazy(() => import("./GlobalProvider"));

const IS_OIDC_DEBUG = readFromLocalStorage("__ENABLE_OIDC_DEBUG", false).value;

export const App = () => {
  const [error, setError] = useState<Error | null>(null);
  const [creds, setCreds] = useState<OpenIdConfig>();

  const onError = (er: Error) => {
    setError(er);
    reportError(er);
  };

  const onRootResize = useCallback(() => {
    const root = document.getElementById("root");
    if (!root) {
      return;
    }
    root.style.height = window.innerHeight + "px";
  }, []);

  useEffect(() => {
    window.addEventListener("resize", onRootResize);

    new InitialApi()
      .getInitialOpenIdCreds()
      .then((v) => {
        if (process.env.CLIENT_ID) {
          v.client_id = process.env.CLIENT_ID;
        }
        setCreds(v);
      })
      .catch(onError);

    return () => {
      window.removeEventListener("resize", onRootResize);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return <ErrorMessage message={error.message} withRefreshButton={true} />;
  }

  if (!creds) {
    return <Spinner />;
  }

  return (
    <OIDCApp
      onError={onError}
      authority={creds.authority}
      clientId={creds.client_id}
      customLogger={IS_OIDC_DEBUG ? console : undefined}
      logLevel={IS_OIDC_DEBUG ? "debug" : "error"}
      onAddSilentRenewError={() => {
        reportMessage(`Reload page due to SilentRenewError`);
      }}
      onSigninCallback={(urlToRestore) => {
        const [pathname, search] = urlToRestore.split("?");

        browserHistory.replace({ pathname, search });
      }}
    >
      <Suspense fallback={<Spinner />}>
        <LazyGlobalProvider />
      </Suspense>
    </OIDCApp>
  );
};
