import classNames from "classnames";
import { createBrowserHistory } from "history";

import { extractLocalized, LangCode } from "@i18n";
import { getConfig } from "@di";

/**
 * FormatJS should be used. This is a deprecated solution
 * @deprecated
 */
export const getUIText = <T>(
  key: string,
  fallback?: T,
  localeOverride?: LangCode
): string => {
  try {
    return (
      extractLocalized(getConfig().texts, fallback, localeOverride)?.[key] ||
      key
    );
  } catch (error) {
    console.error(error);

    return key;
  }
};

export const cn = classNames;

export const browserHistory = createBrowserHistory();
