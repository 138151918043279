import { IS_DEV } from "@constants";
import { di } from "@di";
import { captureException, captureMessage, withScope } from "@sentry/browser";

// TODO: Improve types
export function reportError(message: string, error?: Error): void;
export function reportError(error: Error): void;
export function reportError(message: string | Error, error?: Error): void {
  try {
    if (IS_DEV || di.resolve("configOverrides").isDebug) {
      if (error) {
        console.error(message, error);
      } else if (message instanceof Error) {
        console.error(message);
      } else {
        console.warn(message);
      }
    }
  } catch (exception) {
    console.warn("Unknown exception", exception);
    captureException(exception);
  }

  if (error) {
    captureException(error);
  } else if (message instanceof Error) {
    captureException(message);
  } else {
    captureMessage(message);
  }
}

export function reportErrorWithTag(
  error: Error,
  [tagName, tagValue]: [string, any]
) {
  withScope(function (scope) {
    scope.setTag(tagName, tagValue);
    captureException(error);
  });
}

window._reportErrorWithTag = reportErrorWithTag;

export function reportMessage(msg: string) {
  captureMessage(msg);
}
