import { camelCase, clamp, minBy } from "lodash";
import { Flight } from "@models/flightInfo";
import { DetectionPartial, Detection } from "@models/detection";
import { DetectionEvent } from "@models/event";
import { Turnaround, ExtendedParams } from "@models/turnaround";
import { MutableRefObject } from "react";
import { getConfig } from "@di";
import { Parameter } from "@frontend/configuration";
import { TURN_STATE } from "@constants";

export function camelCaseKeys(obj: any): any {
  if (obj === null) {
    return null;
  }
  if (Array.isArray(obj)) {
    return obj.map(camelCaseKeys);
  }

  const res: any = {};
  Object.entries(obj).forEach(([key, value]) => {
    const newKey = camelCase(key);
    res[newKey] = typeof value === "object" ? camelCaseKeys(value) : value;
  });
  return res;
}

export function convertObjUtcToMilliseconds<T extends Record<string, any>>(
  obj: T
) {
  const result = { ...obj };

  for (const [key, value] of Object.entries(obj)) {
    if (Object.hasOwn(result, key)) {
      result[key as keyof T] =
        typeof result[key] === "number" ? value * 1000 : value;
    }
  }

  return result;
}

export function detectionToEvents(detection: Detection): DetectionEvent[] {
  const res: DetectionEvent[] = [
    {
      id: detection.id + "-start",
      type: detection.startType,
      timestamp: detection.start,
      label: detection.startLabel,
      confidence: detection.startConfidence,
      detectionGap: detection.startDetectionGap,
      detection,
    },
  ];

  if (detection.end && detection.endType) {
    res.push({
      id: detection.id + "-end",
      type: detection.endType,
      timestamp: detection.end,
      label: detection.endLabel,
      confidence: detection.endConfidence,
      detectionGap: detection.endDetectionGap,
      detection,
    });
  }

  return res;
}

export function formatFlightNumber(info: Flight) {
  let res = "";
  const airline = info.companyIata || info.airline;
  res += airline;
  res += info.flightNumber;
  return res.toUpperCase();
}

export function getAirlineIcon(airline?: string): string | undefined {
  const { airlineIcons: airlines } = getConfig();
  if (!airline) {
    return airlines.noAirline;
  }

  const icon = airlines[airline];
  if (!icon) {
    // TODO: Send not found ICAO via Sentry
    console.log("NOT FOUND AIRLINE CODE", airline);
    return airlines.noAirline;
  }

  return airlines[airline];
}

export const getIcaoByTurn = (turn?: Turnaround | null) => {
  return (
    turn?.outboundFlight?.companyIata ||
    turn?.inboundFlight?.companyIata ||
    // @ts-ignore
    turn?.dedicatedAirline ||
    undefined
  );
};

export const mergeRefs = <T extends Element = HTMLDivElement>(
  ...refs: (
    | ((instance: T | null) => void)
    | MutableRefObject<T | null>
    | null
    | undefined
  )[]
) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) {
    return null;
  }
  if (filteredRefs.length === 0) {
    return filteredRefs[0];
  }
  return (inst: T) => {
    for (const ref of filteredRefs) {
      if (typeof ref === "function") {
        ref(inst);
      } else if (ref) {
        // @ts-ignore
        ref.current = inst;
      }
    }
  };
};

export const getCb = (fn?: (e?: Event) => void) =>
  fn ? (e: Event) => fn(e) : null;

export const tsLowerCase = <T extends string>(v: T): Lowercase<T> =>
  v.toLocaleLowerCase() as Lowercase<T>;

export const isDetectionsWithOperationRole = <T extends DetectionPartial>(
  detection: T
): boolean => {
  const { detectionTypesMap: typesMap } = getConfig();
  const type = typesMap[detection.type];
  // Keep detection if its type isn't presented in config
  // It allows to show detections that were generated in middlewares
  // on gantt (and use it in export)
  return !type || type.roles.includes("operation");
};

export const leaveOnlyDetectionsWithOperationRole = <
  T extends DetectionPartial,
>(
  detections: T[]
): T[] => {
  return detections.filter(isDetectionsWithOperationRole);
};

// TODO: Make it part of Turnaround class
export const isTurnStateFinished = (
  turnState: Turnaround["state"]
): boolean => {
  if (!turnState) {
    return false;
  }

  return (
    turnState === TURN_STATE.POST_AIRCRAFT || turnState === TURN_STATE.FINISHED
  );
};

// TODO: Make it part of Turnaround class
export const getActualProgress = (
  v: number | null,
  turnState: Turnaround["state"]
): {
  /**
   * from 0 to 1
   */
  actualProgress: number;
  /**
   * from 0 to 100
   */
  progressInPercents: number;
} | null => {
  if (v === null) {
    return null;
  }

  if (isTurnStateFinished(turnState)) {
    return {
      actualProgress: 1,
      progressInPercents: 100,
    };
  }

  return {
    actualProgress: clamp(v, 0, 1),
    progressInPercents: clamp(Math.floor(v * 100), 0, 100),
  };
};

// TODO: get rid of snake_case
export const getParameter = (source: ExtendedParams, key: Parameter) => {
  if (key === "initial_eobt") {
    return source.initialEobt;
  }

  if (key === "flight_ardt") {
    return source.flightArdt;
  }

  return source[key];
};

export const isLiveStreamStuck = (targetTs: number, loadedTs = 0) => {
  return Math.abs(targetTs - loadedTs) > 60000;
};

export const POSSIBLE_DURATIONS = [
  60 * 24 * 60 * 1000, //24 hours
  60 * 12 * 60 * 1000,
  60 * 6 * 60 * 1000,
  60 * 3 * 60 * 1000,
  60 * 60 * 1000,
  30 * 60 * 1000,
  10 * 60 * 1000,
  5 * 60 * 1000,
] as const;

const getTickCount = (duration: number, tickCount: number) =>
  Math.floor(duration / tickCount);

export const getHumanFriendlyTickDuration = (
  tickDuration: number,
  overallDuration: number,
  targetTicksCount: number
) => {
  let humanReadableDuration =
    minBy(POSSIBLE_DURATIONS, (v) => Math.abs(v - tickDuration)) ||
    tickDuration;

  if (humanReadableDuration !== POSSIBLE_DURATIONS[0]) {
    return humanReadableDuration;
  }

  let tickCount = getTickCount(overallDuration, humanReadableDuration);

  // Increase humanReadableDuration if necessary. Suitable for very long turns
  const limiter = 100;
  let i = 0;
  while (tickCount >= targetTicksCount && i < limiter) {
    humanReadableDuration *= 2;
    tickCount = getTickCount(overallDuration, humanReadableDuration);
    i++;
  }

  return humanReadableDuration;
};

export const filterItemsByStandPatterns = <
  T extends
    | {
        standId: string;
      }
    | {
        id: string;
      },
>(
  items: T[],
  getter: (v: T) => string,
  standPatterns: string[]
) => {
  // Do not filter at all if no stand patterns
  if (!standPatterns.length) {
    return items;
  }

  return items.filter((item) =>
    standPatterns.some((pattern) => {
      const value = getter(item);
      return value.includes(pattern);
    })
  );
};

export const isWritable = <T extends Record<keyof T, T[keyof T]>>(
  obj: T,
  key: keyof T
) => {
  const desc =
    Object.getOwnPropertyDescriptor(obj, key) ||
    Object.getOwnPropertyDescriptor(Object.getPrototypeOf(obj), key);

  return Boolean(desc?.writable);
};

export const isPropertyOfTargetObject = <T extends Object>(
  targetObject: T,
  key: any
): key is keyof T => key in targetObject;
