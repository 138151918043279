import { LangCode } from "@i18n";
import { createContainer } from "awilix";
import { AssaiaUser } from "@frontend/assaia-ui";

import { ApiClient } from "@api/ApiClient";
import { Api } from "@api";

import { ConfigStore } from "@stores/ConfigStore";

import { LocalConfigOverrider } from "@services/localConfigOverrider";

export type DIProps = {
  user: AssaiaUser;
  apiClient: ApiClient;
  api: Api;
  config: ConfigStore;
  configOverrides: LocalConfigOverrider;
  langCode: LangCode;
  locales: LangCode[];
};

export type TDI = typeof di;

//TODO review al usages and try to reduce direct usages and use more injections
export const di = createContainer<DIProps>({ injectionMode: "PROXY" });

export function getConfig() {
  return di.resolve("config");
}

window._di = di;
