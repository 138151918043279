import { LangCode } from "./types";

export const AVAILABLE_LANG_CODES: LangCode[] = ["en", "de"];

export const DEFAULT_LANG_CODE: LangCode = "en";

export const SAVED_LANG_CODE_LOCAL_STORAGE_KEY = "langCode";

export const COMPILED_LOCALES_PATH = "./compiled-lang/";

export const DATE_LOCALE_LOADERS: Record<
  LangCode,
  () => Promise<{ default: Locale }>
> = {
  en: () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "df-en-GB" */
      "date-fns/locale/en-GB"
    ),
  de: () =>
    import(
      /* webpackMode: "lazy", webpackChunkName: "df-de" */
      "date-fns/locale/de"
    ),
} as const;
