import { DEFAULT_LANG_CODE, getUserLanguageCode } from "@i18n";
import { asClass, asValue } from "awilix";
import { ApiClient } from "@api/ApiClient";
import { Api } from "@api";
import { LocalConfigOverrider } from "@services/localConfigOverrider";
import { di } from ".";

export function registerDependencies() {
  di.register({
    apiClient: asClass(ApiClient, { lifetime: "SINGLETON" }),
    api: asClass(Api, { lifetime: "SINGLETON" }),
    configOverrides: asClass(LocalConfigOverrider, { lifetime: "SINGLETON" }),
    langCode: asValue(getUserLanguageCode()),
    locales: asValue([DEFAULT_LANG_CODE]),
  });
}
