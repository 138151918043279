import { Stand } from "@models/stand";

export const getAliasedStandLabel = ({ alias, label }: Stand) => {
  const str = alias ? `${label}/${alias}` : label;

  return str.toUpperCase();
};

export const generateLabelById = (id: string) => {
  return id.split("-").slice(1).join("-");
};

export const getStandLabelByStandId = (
  standIdToStandLabelMap: {
    [standId: string]: string;
  },
  standId?: string
): string | null => {
  if (!standId) {
    return null;
  }

  return standIdToStandLabelMap[standId] || null;
};
