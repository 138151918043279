import { ValuesOf } from "@services/type-utils";
import { defineMessages } from "react-intl";

export const TABS = {
  CURRENT_GATE: "CURRENT_GATE_TAB",
  ALL_GATES: "ALL_GATES_TAB",
  WATCHLIST: "WATCHLIST_TAB",
  SELECTED_TURN: "SELECTED_TURN_TAB",
} as const;

export const TAB_MESSAGES = defineMessages<ValuesOf<typeof TABS>>({
  [TABS.CURRENT_GATE]: {
    defaultMessage: "Current Gate",
    description: "TABS.CURRENT_GATE",
  },
  [TABS.ALL_GATES]: {
    defaultMessage: "All Gates",
    description: "TABS.ALL_GATES",
  },
  [TABS.WATCHLIST]: {
    defaultMessage: "Watchlist",
    description: "TABS.WATCHLIST",
  },
  [TABS.SELECTED_TURN]: {
    defaultMessage: "Selected Turnaround",
    description: "TABS.SELECTED_TURN",
  },
});
