import { useIntlOutsideProvider } from "@hooks/useIntlOutsideProvider";
import React, { ComponentProps, PropsWithChildren, ReactNode } from "react";
import { cn } from "@services/react";
import {
  Icons24Alert,
  Icons24LoopLeftLine,
  NavItemButton,
  Spinner,
} from "@frontend/assaia-ui";
import { reportMessage } from "@services/logger";
import { PropsWithClass } from "@models/common";

import s from "./style.module.scss";

type ConditionalProps<T> =
  | ({
      withRefreshButton?: true;
      refreshButtonProps?: ComponentProps<typeof NavItemButton>;
    } & T)
  | ({
      withRefreshButton?: false;
      refreshButtonProps?: never;
    } & T);

export type ErrorMessageProps = PropsWithChildren<
  PropsWithClass<ConditionalProps<{ message?: ReactNode }>>
>;

export const ErrorMessage = ({
  message,
  className,
  children,
  withRefreshButton,
  refreshButtonProps = {},
}: ErrorMessageProps) => {
  const intl = useIntlOutsideProvider();

  const defaultTitle = intl ? (
    intl.formatMessage(
      {
        defaultMessage:
          "Assaia ApronAI is unavailable. {br} Please try later...",
        description: "Error Message default title",
      },
      { br: <br /> }
    )
  ) : (
    <Spinner />
  );

  const refreshButton = withRefreshButton ? (
    <NavItemButton
      label="Retry"
      icon={<Icons24LoopLeftLine />}
      {...refreshButtonProps}
      className={cn(s.refreshBtn, refreshButtonProps.className)}
      onClick={(event) => {
        reportMessage(`Reload page from error page`);
        window.location.reload();
        refreshButtonProps?.onClick?.(event);
      }}
    />
  ) : null;

  return (
    <div className={cn(s.errorMessage, className)}>
      <Icons24Alert className={s.towerIcon} />
      <h1 className={s.title}>{message || defaultTitle}</h1>
      {refreshButton}
      {children}
    </div>
  );
};
